<template>
<!--    <b-card no-body>
        <b-card-header>
            <b-card-title>
                Новый тикет
            </b-card-title>
        </b-card-header>
        <b-card-body style='padding: 24px 32px;'>
            
            <b-card-title>Сервис</b-card-title>
            
            <b-card-title style='font-size: 20px;'>
                Новое обращение
            </b-card-title>
            
            <b-row>
                <b-col v-for="subject in Tickets.subjects.items" md="6" cols="12" class='mb-1'>
                    <div :class=" subject.id === form.subject ? 'card-picker active' : 'card-picker' " @click='form.subject = subject.id'>
                        <feather-icon :icon="subject.icon" size="32" :class="{
                           'text-muted' : subject.id === form.subject ? false : true,
                           'text-white' : subject.id === form.subject ? true : false
                        }" />
                        <p class="header">{{ subject.title }}</p>
                        <span class="text">{{ subject.description }}</span>
                    </div>
                </b-col>
            </b-row>
            
            <template v-if='childs.length > 0'>
                <hr class='mt-1 mb-2' />
                <b-card-title class='font-weight-bolder'>Категория обращения</b-card-title>
                <div class="d-flex flex-wrap">
                    <div v-for="child in childs" @click='form.child = child.id' style="margin-right: 8px; margin-bottom: 6px; padding: 10px 14px;" :class="{
                        'card-picker' : true,
                        'active' : form.child === child.id
                    }" >
                        <p class='header m-0'>{{ child.title }}</p>
                    </div>
                </div>
            </template>
            
            <template v-if="form.subject === 1 && form.child && listOfContracts.length > 0">
                <hr class='mt-2 mb-2' />
                <b-card-title class='font-weight-bolder'>Контракт</b-card-title>
                <div class="d-flex flex-wrap">
                    <div v-for="contract in listOfContracts" style="margin-right: 8px; margin-bottom: 6px; padding: 10px 14px;;" :class="{
                        'card-picker' : true,
                        'active' : false
                    }" >
                        <p class='header m-0 font-weight-bolder'>{{ contract.guuid }}</p>
                        <span class="text">{{ contract.ths.formatMoney(0," "," ") }} TH/s</span>
                    </div>
                </div>
            </template>
            
        </b-card-body>
    </b-card>-->
</template>

<script>

    import vSelect from 'vue-select'
    import Cleave from 'vue-cleave-component'
    import Tickets from "@/modules/tickets/"

    export default {

        data() {
            return {
                Tickets,
                form: {
                    subject: null,
                    child: null
                },
                is_loading: false,
                listOfContracts: []
            }
        },
        methods: {
            feath() {
                this.listOfContracts = [];
                this.is_loading = true;
                this.$request.get("contracts.feath", {
                    type: this.form.subject
                }).then(rsp => {
                    this.listOfContracts = rsp.list;
                    this.is_loading = false;
                })
            }
        },
        components: {
            vSelect
        },
        computed: {
            childs() {
                
                if( this.form.subject ) {
                    return this.Tickets.subjects.items.find(item => item.id === this.form.subject).childs;
                }
                
                return [];
                
            }
        },
        watch: {
            "form.subject" : function( val ) {
                this.form.child = null;
                this.feath();
            }
        },
        mounted() {

        }

    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
 